/* eslint-disable @nx/enforce-module-boundaries */
import { HandShakeSimpleIcon } from '@mybridge/icons';
import { Box, HStack } from '@mybridge/ui/layout';
import { Link } from '@mybridge/ui/link';
import { Text } from '@mybridge/ui/text';
import { useContext, useMemo } from 'react';
import { PostBoxContext } from '../context';
import { useRouter } from 'next/navigation';
import { InsightPopover } from '../insight-popover';
import { getUserFullName } from 'v4/lib/commons';
import _ from 'lodash';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
// import Link from "next/link"

export const PostBoxInsightPlate = ({
  maxCount = 15,
  handleCommentClicked,
  isReel,
  isSearch = false,
} = {}) => {
  const { push } = useRouter();
  const { t } = useTranslator();
  const { post, hasInsights, postModalLink, commentModal, setCommentModal } =
    useContext(PostBoxContext);
  const {
    comment_count,
    repost,
    like_count,
    dislike_count,
    liked_by,
    dislike_by,
    reposts_by,
    comment_by,
  } = post ?? {};

  const likeDislikeCount = useMemo(() => {
    return (like_count ?? 0) + (dislike_count ?? 0);
  }, [like_count, dislike_count]);

  const likedBy = liked_by
    ?.filter?.((_, ind) => ind < maxCount)
    ?.map?.((lb, i) => (
      <Box key={i}>
        {lb?.author_type === 'PAGE' ? lb?.name : getUserFullName(lb)}
      </Box>
    ));
  if (liked_by?.length > maxCount) {
    likedBy.push(<Box>{t('scheduling.and') || "and"} {liked_by?.length - maxCount} {t('otherModules.more') || "more"}</Box>);
  }
  const dislikedBy = dislike_by
    ?.filter?.((_, ind) => ind < maxCount)
    ?.map?.((lb, i) => (
      <Box key={i}>
        {lb?.author_type === 'PAGE' ? lb?.name : getUserFullName(lb)}
      </Box>
    ));
  if (dislike_by?.length > maxCount) {
    dislikedBy.push(<Box>{t('scheduling.and') || "and"} {dislike_by?.length - maxCount} {t('otherModules.more') || "more"}</Box>);
  }
  const repostBy = _.uniqBy(reposts_by ?? [], (e) => e.id)
    ?.filter?.((_, ind) => ind < maxCount)
    .map?.((lb, i) => (
      <Box key={i}>
        {lb?.author_type === 'PAGE' ? lb?.name : getUserFullName(lb)}
      </Box>
    ));
  if (reposts_by?.length > maxCount) {
    repostBy.push(<Box>{t('scheduling.and') || "and"} {reposts_by?.length - maxCount} {t('otherModules.more') || "more"}</Box>);
  }

  const commentBy = comment_by
    ?.filter?.((_, ind) => ind < maxCount)
    ?.map?.((lb, i) => (
      <Box key={i}>
        {lb?.author_type === 'PAGE' ? lb?.name : getUserFullName(lb)}
      </Box>
    ));
  if (comment_by?.length > maxCount) {
    commentBy.push(<Box>{t('scheduling.and') || "and"} {comment_by?.length - maxCount} {t('otherModules.more') || "more"}</Box>);
  }

  return (
    <>
      {hasInsights ? (
        <HStack px={4} py={2}>
          <HStack>
            <HStack spacing={0}>
              {like_count > 0 ? (
                <InsightPopover content={likedBy}>
                  <Box>
                    <HandShakeSimpleIcon width="14" height="14" color="green" />
                  </Box>
                </InsightPopover>
              ) : (
                ''
              )}
              {dislike_count > 0 ? (
                <InsightPopover content={dislikedBy}>
                  <Box>
                    <HandShakeSimpleIcon width="14" height="14" color="red" />
                  </Box>
                </InsightPopover>
              ) : (
                ''
              )}
            </HStack>
            {likeDislikeCount > 0 ? (
              <Text color={isReel ? 'white' : 'brandGray.700'}>
                {likeDislikeCount}
              </Text>
            ) : (
              ''
            )}
          </HStack>

          {/* need to discuss with sola */}
          <HStack flex={1} justifyContent="flex-end">
            {comment_count > 0 ? (
              <InsightPopover content={commentBy}>
                {isReel ? (
                  <Text
                    color={'white'}
                    onClick={() => {
                      handleCommentClicked();
                    }}
                  >
                    {`${comment_count} ${
                      comment_count === 1 ? t('post.comment') || 'Comment' : t('post.comments') || 'Comments'
                    }`}
                  </Text>
                ) : (
                  <Link
                    shallow={true}
                    onClick={() => {
                      localStorage?.setItem('commentCLicked', true);
                    }}
                    // href={postModalLink(post?.id)}
                    href={`/post/${post?.id}`}
                    color={isReel ? 'white' : 'brandPrimary.500'}
                  >
                    {comment_count} {' '}
                    {comment_count === 1 ? t('post.comment') || 'Comment' : t('post.comments') || 'Comments'}
                  </Link>
                )}
              </InsightPopover>
            ) : (
              ''
            )}
            {repostBy?.length > 0 ? (
              <InsightPopover content={repostBy}>
                <Link color={isReel ? 'white' : 'brandPrimary.500'}>
                  {repostBy?.length} {repostBy.length < 2 ? t('post.share') || 'Share' : t('post.shares') || 'Shares'}
                </Link>
              </InsightPopover>
            ) : (
              ''
            )}
          </HStack>
        </HStack>
      ) : (
         <Box minH="20px"></Box>
      )}
    </>
  );
};
